@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Medium.ttf");
}

@font-face {
  font-family: "Outfit-Bold";
  src: url("./assets/fonts/Outfit-Bold.ttf");
}

@font-face {
  font-family: "Outfit-Regular";
  src: url("./assets/fonts/Outfit-Regular.ttf");
}

@font-face {
  font-family: "Times";
  src: url("./assets/fonts/times-roman/OPTITimes-Roman.otf");
}

@font-face {
  font-family: "Prada";
  src: url("./assets/fonts/times-roman/OPTITimes-Roman.otf");
}

@import url("https://fonts.googleapis.com/css2?family=Kaisei+Opti&display=swap");

:root {
  margin: 0;
  padding: 0;
  --body-fonts: "Kaisei Opti", serif !important;
}
body {
  font-family: var(--body-fonts);
  overflow-x: hidden !important;
}

body,
html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--body-fonts);
}

p {
  font-family: var(--body-fonts);
}

h1 h2 h3 h4 h5 h6 {
  font-family: var(--body-fonts);
}

ul li {
  list-style: none;
  text-decoration: none;
  font-family: var(--body-fonts);
}

.button-top {
  background: rgba(255, 255, 255, 0.11);
  box-shadow: 0px 2px 2px 0px rgba(6, 7, 9, 0.22);
}

.parallax {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.outlined {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000; /* Outline color */
}

.parallax {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

/* whatsapp icon */
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}


.icon-container {
  position: relative;
  width: 70px;
  height: 70px; /* Height for two stacked squares */
}

.square {
  z-index: 1; /* Set a higher z-index to place it on top */
}